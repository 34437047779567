import $ from 'jquery'
import slick from 'slick-carousel'
import 'magnific-popup';

const $doc = $(document)


function cleanup(){
  this.content.find('.js-team-slider').slick('unslick')
}
function startPopup() {
  this.content.find('.js-team-slider').slick({
    slidesToShow: 1,
    dots: false,
    arrows: true,
    adaptiveHeight: true,
    initialSlide: this.st.el.data('initialslide')
  });
}

export default function initTeam(){

  $('.js-team-popup').magnificPopup({
    type:'inline',
    midClick: true,
    mainClass: 'popup--animate',
    callbacks:{
      open: startPopup,
      close: cleanup,
    }
  });

}
