import $ from 'jquery'

export default function initStickies(){
  if ( ($('body').hasClass('single-post')) ) {
    let topDiff = $('.js-sticky-share').offset().top;
    let cutOffDiff = $('article.post').next().offset().top;
    $(window).on('scroll', function() {
      if ($(window).scrollTop() > topDiff - 250) {
        $('.js-sticky-share').css({position: 'fixed', top:'250px'});
        if ($(window).scrollTop() > (cutOffDiff - 310)) {
          $('.js-sticky-share').addClass('stopfixed');
          $('.article.post').addClass('stopfixed');
        } else {
          $('.js-sticky-share').removeClass('stopfixed');
          $('.article.post').removeClass('stopfixed');
        }
      } else {
        $('.js-sticky-share').css({position: 'absolute', top:'740px'});
      }
    });
  }
  if ( ($('body').hasClass('single-resource')) ) {
    let topBoxDiff = $('.js-sticky-box').offset().top;
    let topBoxWidth = $('.js-sticky-box').width() + 120;
    let topBoxHeight = $('.js-sticky-box').outerHeight();
    let boxOffset = $(window).width() - ($('.js-sticky-box').offset().left + topBoxWidth);
    let cutOffDiff = $('article.resource').next().offset().top;
    $(window).on('resize', function() {
      topBoxDiff = $('.js-sticky-box').offset().top;
      topBoxWidth = $('.js-sticky-box').width() + 120;
      topBoxHeight = $('.js-stick-box').height();
      boxOffset = $(window).width() - ($('.js-sticky-box').offset().left + topBoxWidth);
      cutOffDiff = $('article.resource').next().offset().top;
    });
    $(window).on('scroll', function() {
      if ($(window).width() > 960) {
        if ($(window).scrollTop() > topBoxDiff - 90) {
          if ($(window).scrollTop() > (cutOffDiff - topBoxHeight - 90 - 96)) {
            $('.js-sticky-box').css({position: 'absolute', top:'auto', bottom: '0', width: '33.33333%', right: '0', height: topBoxHeight+'px'});
          } else {
            $('.js-sticky-box').css({position: 'fixed', top:'90px', width: topBoxWidth+'px', right: boxOffset+'px', bottom: 'auto'});
          }
        } else {
          $('.js-sticky-box').css({position: 'absolute', top:'0', width: '33.33333%', right: '0', height: 'auto', bottom: 'auto'});
        }
      } else {
        $('.js-sticky-box').css({position: 'relative', top:'initial', width: 'inherit', height: 'auto', bottom: 'initial'});
      }
    });
  }
}
