import $ from 'jquery'

const $doc = $(document)
const $body = $('body')
const $mobileNav = $('.js-mobile-nav')
const $hamburger = $('.js-hamburger')

function showMobileNav(event) {
  event.preventDefault()
  $mobileNav.toggleClass('is-active')
  $hamburger.toggleClass('is-active')
  $body.toggleClass('nav-is-active')
  if ( $mobileNav.hasClass('is-active')) {
    $hamburger.attr('aria-expanded', 'true')
  } else {
    $hamburger.attr('aria-expanded', 'false')
  }
}

export default function initMobileNav(){
  $doc.on('click', '.js-hamburger', showMobileNav )
  $doc.on('keydown', function(event) {
    if ( $mobileNav.hasClass('is-active') && (event.key == "Escape") ) {
      $hamburger.attr('aria-expanded', 'false')
      $mobileNav.toggleClass('is-active')
      $hamburger.toggleClass('is-active')
      $body.toggleClass('nav-is-active')
    }
  })
  $doc.on('focusin', function (event) {
    var $target = $(event.target);
    if (!$target.closest('.js-header-wrapper').length) {
      if ( $mobileNav.hasClass('is-active') ) {
        $hamburger.attr('aria-expanded', 'false')
        $mobileNav.toggleClass('is-active')
        $hamburger.toggleClass('is-active')
        $body.toggleClass('nav-is-active')
      }
    }
  })
  $('.js-mobile-nav-primary .menu-item-has-children').attr('aria-expanded', 'false');
  $('.js-mobile-nav-primary .menu-item-has-children').append( '<span><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_2133:19172)"><path d="M14.745 5.3335L8.0392 12.0393L1.3334 5.3335" stroke="#103B51" stroke-width="1.58057"/></g><defs><clipPath id="clip0_2133:19172"><rect width="16" height="16" fill="white"/></clipPath></defs></svg></span>');
  $('.js-mobile-nav-primary .menu-item-has-children > span').on('click', function() {
    $(this).siblings('.sub-menu').slideToggle(500);
    $(this).parent().toggleClass('submenu-active');
    $(this).parent().attr('aria-expanded',
      $(this).parent().attr('aria-expanded')=='false' ? 'true' : 'false'
    );
  });
}
