import 'lazysizes'
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'

import $ from 'jquery'
import initGreenSock  from '/lib/greensock'
import initHeadroom   from '/lib/headroom'
import initMobileNav  from '/lib/mobile-nav'
import initCookies    from '/lib/cookies'
import initPopups     from '/lib/popups'
import initBgShapes from './lib/bg-shapes'
import initSlick from './lib/sliders'
import initFormEdits from './lib/form-edits'
import initShareButtons from './lib/share-buttons'
import initTeam from './lib/team'
import initEvents from './lib/events'
import initLoadMore from './lib/loadmore'
import initStickies from './lib/sticky'

initGreenSock()
initHeadroom()
initMobileNav()
initCookies()
initPopups()
initBgShapes()
initSlick()
initFormEdits()
initShareButtons()
initTeam()
initEvents()
initLoadMore()
initStickies()
