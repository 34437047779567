import $ from 'jquery'

export default function initLoadMore(){
  $('.js-events-loadmore').on('click', function(e) {
    var button = $(this),
      data = {
      'action': 'loadmore_events',
      'query': posts,
      'page' : current_page,
      'offset' : offset
		};

		$.ajax({
			url : ajaxurl,
			data : data,
			type : 'POST',
			beforeSend : function () {
				button.text('Loading...');
			},
			success : function( data ){
				if( data ) {
					button.text( 'Show more events' );
          $(".js-events-grid-standard").append(data);
					current_page++;
          offset = offset + 9;
					if ( current_page >= max_pages ) {
            button.remove();
          }
				} else {
					button.remove();
				}
			}
		});
  });
  $('.js-blog-loadmore').on('click', function(e) {
    var button = $(this),
      data = {
      'action': 'loadmore_blog',
      'query': posts,
      'page' : current_page,
      'offset' : offset
		};

		$.ajax({
			url : ajaxurl,
			data : data,
			type : 'POST',
			beforeSend : function () {
				button.text('Loading...');
			},
			success : function( data ){
				if( data ) {
					button.text( 'Show more blog posts' );
          $(".js-blog-grid").append(data);
					current_page++;
          offset = offset + 9;
					if ( current_page >= max_pages ) {
            button.remove();
          }
				} else {
					button.remove();
				}
			}
		});
  });
}
