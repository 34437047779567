import $ from 'jquery'
export default function initBgShapes(){
  $('.building-block--has-bottom-shape').each(function() {
    if ( $(this).next().hasClass('building-block--bg-white') ) {
      $(this).find('.building-block__bg__shape').addClass('white-after');
    }
    if ( $(this).next().hasClass('building-block--bg-beige') ) {
      $(this).find('.building-block__bg__shape').addClass('beige-after');
    }
    if ( $(this).next().hasClass('building-block--bg-navy') ) {
      $(this).find('.building-block__bg__shape').addClass('navy-after');
    }
    if ( $(this).next().hasClass('building-block--bg-blue') ) {
      $(this).find('.building-block__bg__shape').addClass('blue-after');
    }
    if ( $(this).next().hasClass('building-block--bg-teal') ) {
      $(this).find('.building-block__bg__shape').addClass('teal-after');
    }
  });
}
