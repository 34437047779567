import $ from 'jquery'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

function animateSimple(elem) {
  gsap.to(elem, {
    duration: 0.5,
    y: 0,
    autoAlpha: 1,
    ease: "power1.out",
    stagger: 0.2,
    delay: 0.3,
    overwrite: 'auto'
  })
}

function setupFadeInElement(elem) {
  hide(elem) // assure that the element is hidden when scrolled into view
  ScrollTrigger.create({
    trigger: elem,
    onEnter: function() { animateSimple(elem) }
  })
}

function animateFade(elem) {
  gsap.to(elem, {
    duration: 0.5,
    autoAlpha: 1,
    ease: "power1.out",
    delay: 0.2,
    overwrite: 'auto'
  })
}

function setupFadeElement(elem) {
  hideFade(elem) // assure that the element is hidden when scrolled into view
  ScrollTrigger.create({
    trigger: elem,
    onEnter: function() { animateFade(elem) }
  })
}

function setupParallaxElement(elem) {
  gsap.to(elem, {
    scrollTrigger: {
      trigger: $(elem).parent(),
      start: "top 90%",
      end: "bottom 15%",
      scrub: true,
      // markers: true
    },
    top: 0,
    ease: "linear",
    duration: 3
  })
}

function setupHeroPeel(elem) {
  setTimeout(function () {
    $(elem).addClass('is-animating')
  },4600)
  ScrollTrigger.create({
    trigger: elem,
    start: "bottom 90%",
    onEnter: function() {
      $(elem).removeClass('is-animating')
      $(elem).addClass('finish-animating')
    },
    onUpdate: self => console.log("progress:", self.progress)
  })
}

function hide(elem) {
  gsap.set(elem, {autoAlpha: 0, y: 40})
}

function hideFade(elem) {
  gsap.set(elem, {autoAlpha: 0})
}

function init(){
  gsap.registerPlugin(ScrollTrigger)
  gsap.utils.toArray(".js-fadein").forEach( setupFadeInElement )
  gsap.utils.toArray(".js-fade").forEach( setupFadeElement )
  setupHeroPeel(".js-hero-peel")
  gsap.utils.toArray(".js-parallax").forEach( setupParallaxElement )
}

export default function initGreenSock(){
  $(document).ready( init )
}
