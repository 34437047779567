import $ from 'jquery'

export default function initFormEdits(){
  $('.ginput_container_select .gf_placeholder').attr('disabled','disabled');
  $('.gfield_select').addClass('unselected');
  $('.gfield_select').on('change', function() {
    $(this).removeClass('unselected');
  });
  $('.gform_body').prepend('<p class="form__errormsg hidden">Please fill out the required fields.</p>');
  $('form[id^="gform_"]').on('change', function (e) {
    var $reqd = $(this).find('.gfield_contains_required.gfield_visibility_visible').filter(function (i, c) {
      return []
        .concat($(c).find('input[type="text"], input[type="email"], textarea').filter(function (i, fl) { return $(fl).val().length == 0; }).get())
        .concat($(c).find('input[type="checkbox"]').not(':checked').get())
        .length;
    });
    if ($reqd.length) {
      $(this).find('input[type="submit"]').addClass('disabled button-disabled').attr('disabled', 'disabled');
      $(this).find('.gform_footer').addClass('submit-disabled');
      $(this).find('.form__errormsg').removeClass('filled');
    } else {
      $(this).find('input[type="submit"]').removeClass('disabled button-disabled').removeAttr('disabled');
      $(this).find('.gform_footer').removeClass('submit-disabled');
      $(this).find('.form__errormsg').addClass('hidden').addClass('filled');
    }
  }).trigger('change');
  $('.gform_footer.submit-disabled').on('click', function() {
    $(this).parent().find('.form__errormsg').removeClass('hidden');
  });
  $(document).on('gform_confirmation_loaded', function(event, formId){
    if(formId == 2 || formId == 4) {
      $('.popup--form').addClass('form-submitted');
    }
  });
}
