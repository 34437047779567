import $ from 'jquery'

export default function initEvents(){
  if ( $('body').hasClass('post-type-archive-tribe_events') ) {
    if( window.location.pathname === "/events/" ){
      $('body').addClass('default-events');
      $('.tribe-events-header__messages').addClass('hidden');
      let today = new Date();
      let weekday = new Array(7);
          weekday[0] = "Sun";
          weekday[1] = "Mon";
          weekday[2] = "Tues";
          weekday[3] = "Wed";
          weekday[4] = "Thurs";
          weekday[5] = "Fri";
          weekday[6] = "Sat";
      let day = weekday[today.getDay()];
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
      ];
      let month = monthNames[today.getMonth()];
      let dd = today.getDate();
      let todayText = day+', '+month+' '+dd;
      $('.tribe-events-c-top-bar__datepicker-mobile').html(todayText);
      $('.tribe-events-c-top-bar__datepicker-desktop').html(todayText);
    } else {
      $('.js-events-grid-standard').hide();
      $('.js-events-loadmore').hide();
      $('.js-events-grid-filtered').show();
      $('.js-events-showall').show();
    }
    if( window.location.pathname === "/events/list/" ) {
      $('.js-events-showall').html('Show all upcoming events');
    }
    $('.tribe-events-c-top-bar__datepicker-input').on('change',function(e){
      $('body').removeClass('default-events');
      $('.tribe-events-header__messages').removeClass('hidden');
    });
  }
}
