import $ from 'jquery'
import slick from 'slick-carousel'

export default function initSlick(){
  const settings_mobile = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    mobileFirst: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 960,
        settings: "unslick"
      }
    ]
  };
  const settings_default = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    mobileFirst: true,
    infinite: true,
    centerMode: true,
    centerPadding: 100,
  };

  const mobileSlider =  $('.js-mobile-carousel').slick(settings_mobile);
  const defaultSlider =  $('.js-carousel').slick(settings_default);

  setTimeout(function(){
    $('.js-mobile-carousel.slick-initialized').addClass('slick-loaded');
    $('.js-carousel.slick-initialized').addClass('slick-loaded');
  }, 300);

  $(window).on('resize', function() {
    $('.js-mobile-carousel.slick-initialized').removeClass('slick-loaded');
    if( $(window).width() < 960 &&  !mobileSlider.hasClass('slick-initialized')) {
      $('.js-mobile-carousel').slick(settings_mobile);
      setTimeout(function(){
        $('.js-mobile-carousel.slick-initialized').addClass('slick-loaded');
      }, 300);
    }
  });
}
