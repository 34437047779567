import 'magnific-popup';
import $ from 'jquery';

export default function initPopups(){
  $('.js-popup').magnificPopup({
    type:'inline',
    midClick: true,
    mainClass: 'popup--animate',
    callbacks: {
      close: function() {
        if ( $( ".js-internal-video" ).length ) {
          $('.js-internal-video').trigger('pause');
        }
      }
    }
  });
  $('.js-menu-popup').magnificPopup({
    type:'inline',
    midClick: true,
    mainClass: 'popup--animate',
  });
  $('.js-external-video-popup').magnificPopup({
    type: 'iframe',
    mainClass: 'popup--animate popup--iframe',
  });
  $('.js-image-popup').magnificPopup({
    type: 'image',
    mainClass: 'popup--animate',
  });
  $('.js-close-popup').on( 'click', function() {
    $.magnificPopup.close();
  });
}
